import * as React from "react";
import { Button } from "@fluentui/react-components";

export interface StartPageBodyProps {
  login: () => {};
}

export const StartPageBody = (props: StartPageBodyProps) => {
  return (
    <div className="ms-welcome">
      <div className="ms-welcome__main">
        <Button className="ms-welcome__action" onClick={props.login}>
          Zaloguj się
        </Button>
      </div>
    </div>
  );
};
