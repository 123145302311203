import * as React from "react";
import Progress from "./Progress";
import { signInO365 } from "../../../utilities/office-apis-helpers";
import OfficeAddinMessageBar from "./OfficeAddinMessageBar";
import { StartPageBody } from "./StartPageBody";
import { LoginPageBody } from "./LoginPageBody";
import { ISharepointListElement } from "../../dtos/SharepointList.interface";
import { Divider, Spinner } from "@fluentui/react-components";
import { Header } from "./Header";
import { Search } from "./Search";
import Entry from "./Entry";
import Footer from "./Footer";

// Deploy: https://learn.microsoft.com/en-us/office/dev/add-ins/publish/publish-add-in-vs-code
// Based on: https://github.com/OfficeDev/Office-Add-in-samples/blob/main/Samples/auth/Office-Add-in-Microsoft-Graph-React/src/components/App.tsx

/* global console, fetch, Word, Office */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  authStatus?: string;
  fileFetch?: string;
  headerMessage?: string;
  errorMessage?: string;
}

export default function App(props: AppProps) {
  // Auth state
  const { title, isOfficeInitialized } = props;
  const [authStatus, setAuthStatus] = React.useState<string>("notLoggedIn");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [accessToken, setAccessToken] = React.useState<string>("");

  // App state
  const [query, setQuery] = React.useState<string>("");
  const [selectedTags, setSelectedTags] = React.useState<string[]>([""]);
  const [selectedDepartment, setSelectedDepartment] = React.useState<string[]>([""]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<"PL" | "EN">("PL");

  const [selected, setSelected] = React.useState<string>(null);
  const [listItems, setListItems] = React.useState<ISharepointListElement[]>([]);
  const [filteredItems, setFilteredItems] = React.useState<ISharepointListElement[]>(listItems);
  const [tags, setTags] = React.useState<string[]>([]);
  const department = ["Digital", "PR"];

  function errorDismissed() {
    setErrorMessage("");
    setAuthStatus((authStatus) => {
      if (authStatus === "loginInProcess") {
        return "notLoggedIn";
      }
      return "";
    });
  }
  const insertTextToFile = async () => {
    return await Word.run(async (context) => {
      const range = context.document.getSelection();
      const text = listItems.find((item) => item.id === selected);
      const content = range.insertHtml(text.fields.Opis ?? "Wybierz usługę z panelu", Word.InsertLocation.start);
      content.font.set({
        name: "Inter",
        size: 12,
      });
      content.paragraphs.getFirst().lineSpacing = 10;
    });
  };
  const login = async () => {
    await signInO365(setAuthStatus, setErrorMessage, setAccessToken);
  };

  // UseEffects;
  // TODO: Logging in from current window. Not working for now
  // React.useEffect(() => {
  //   Office.context.auth
  //     .getAccessToken({
  //       allowSignInPrompt: true,
  //       allowConsentPrompt: true,
  //       forMSGraphAccess: true,
  //     })
  //     .then((token) => {
  //       console.log(token);
  //       setAuthStatus("loggedIn");
  //       setAccessToken(token);
  //     });
  // }, []);

  // Fetching data from sharepoint list
  React.useEffect(() => {
    fetch("https://relations-social-media-updater.azurewebsites.net/ofertownik", {
      headers: {
        TenantId: "4c052e42-013d-4676-9f4b-b87bf5db00e8",
        AccessToken: accessToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data: ISharepointListElement[]) => {
        // Fix for second call and backend returning error
        if (listItems.length === 0) {
          setListItems(data);
          setFilteredItems(data);

          console.log(data);

          let tempTags = data.map((item) => item.fields.Us_x0142_uga_x0020_og_x00f3_lna.Label);
          tempTags = tempTags.filter((item, index) => tempTags.indexOf(item) === index);
          tempTags.push("");
          setTags(tempTags);
        }
      });
  }, [accessToken]);

  // Filtering items on search
  React.useEffect(() => {
    setFilteredItems(
      listItems.filter(
        (item) =>
          item.fields.Title.toLowerCase().includes(query.toLowerCase()) &&
          item.fields.Us_x0142_uga_x0020_og_x00f3_lna.Label.includes(selectedTags[0]) &&
          item.fields.Dzia_x0142_.Label.includes(selectedDepartment[0]) &&
          item.fields.J_x0119_zyk.includes(selectedLanguage)
      )
    );

    console.log(filteredItems);
  }, [query, selectedTags, selectedDepartment, selectedLanguage]);

  React.useEffect(() => {
    console.log(selectedLanguage);
  }, [selectedLanguage]);

  // Display cases
  // 1. Application is loading
  if (!isOfficeInitialized) {
    return <Progress title={title} logo="assets/Onedrive_Charts_icon_80x80px.png" message="Ładowanie aplikacji." />;
  }
  // 2. Application is loaded, but user is not logged in
  if (authStatus === "notLoggedIn") {
    return <StartPageBody login={login} />;
  }
  // 3. If application is not Word
  if (Office.HostType.Word === undefined) {
    return <div className="ms-welcome">Aplikacja działa tylko w Wordzie</div>;
  }
  // 3. Application is loaded, user is logging in in another window
  if (authStatus === "loginInProcess") {
    return <LoginPageBody />;
  }
  // 4. Application is downloading data
  if (listItems.length === 0) {
    return (
      <div className="ms-welcome">
        <Spinner label="Pobieranie danych" />
      </div>
    );
  }

  return (
    <div className="ms-welcome">
      {errorMessage ? <OfficeAddinMessageBar onDismiss={errorDismissed} message={errorMessage + " "} /> : null}
      <Header />
      <Search
        query={query}
        setQuery={setQuery}
        tags={tags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        departments={department}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <Divider />
      {filteredItems.map((item) => (
        <Entry
          name={item.fields.Title}
          content={item.fields.Opis}
          key={item.id}
          id={item.id}
          selected={selected}
          setSelected={setSelected}
          insertFunction={insertTextToFile}
        />
      ))}
      <Footer insertFunction={insertTextToFile} />
    </div>
  );
}
