import * as React from "react";
import { Dropdown, Field, Input, InputProps, makeStyles, Option, Radio, RadioGroup } from "@fluentui/react-components";

interface SearchProps {
  query: string;
  setQuery: (query: string) => void;
  tags: string[];
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  departments: string[];
  selectedDepartment: string[];
  setSelectedDepartment: (department: string[]) => void;
  selectedLanguage: "PL" | "EN";
  setSelectedLanguage: (language: "PL" | "EN") => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  dropdown: {
    marginTop: ".5rem",
  },
});

export const Search = (props: SearchProps) => {
  const { query, setQuery } = props;
  const styles = useStyles();
  const [value, setValue] = React.useState<string>("");
  const [departmentValue, setDepartmentValue] = React.useState<string>("");

  const onChange: InputProps["onChange"] = (_ev, data) => {
    setQuery(data?.value ?? "");
  };

  const onOptionSelect = (_ev, data) => {
    props.setSelectedTags(data.selectedOptions);
    setValue(data.optionText ?? "");
  };
  const onDepartmentSelect = (_ev, data) => {
    props.setSelectedDepartment(data.selectedOptions);
    setDepartmentValue(data.optionText ?? "");
  };

  return (
    <div className={styles.root}>
      <Field label="Język">
        <RadioGroup
          layout="horizontal"
          value={props.selectedLanguage}
          onChange={(_, data) => props.setSelectedLanguage(data.value as "PL" | "EN")}
        >
          <Radio value="PL" label="PL" />
          <Radio value="EN" label="EN" />
        </RadioGroup>
      </Field>
      <Input id="search" appearance="outline" value={query} onChange={onChange} placeholder="Wyszukaj usługę" />
      <Dropdown
        id="department"
        className={styles.dropdown}
        value={departmentValue}
        selectedOptions={props.selectedDepartment}
        onOptionSelect={onDepartmentSelect}
        placeholder="Wybierz dział blah blah"
      >
        <Option text={props.departments[0]} value={props.departments[0]} key={props.departments[0]}>
          {props.departments[0]}
        </Option>
        <Option text={props.departments[1]} value={props.departments[1]} key={props.departments[1]}>
          {props.departments[1]}
        </Option>
        <Option text="" value="" key="empty"></Option>
      </Dropdown>
      <Dropdown
        id={`tags`}
        className={styles.dropdown}
        value={value}
        selectedOptions={props.selectedTags}
        onOptionSelect={onOptionSelect}
        placeholder="Wybierz kategorię"
      >
        {props.tags.map((tag) => (
          <Option text={tag} value={tag} key={tag}>
            {tag}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};
