import { Spinner } from "@fluentui/react-components";
import * as React from "react";

export const LoginPageBody = () => {
  return (
    <div className="ms-welcome">
      <div className="ms-welcome__main">
        <Spinner className="spinner" label="Logowanie w oknie." />
      </div>
    </div>
  );
};
