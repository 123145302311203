/* global Office */

let loginDialog: Office.Dialog;
const dialogLoginUrl: string = "https://ofertownik.relations365.net/login/login.html";
// const dialogLoginUrl: string = "https://localhost:3000/login/login.html";

export const signInO365 = async (
  setAuthStatus: (x: string) => void,
  setErrorMessage: (x: string) => void,
  setAccessToken: (x: string) => void
) => {
  setAuthStatus("loginInProcess");

  await Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 40, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      setErrorMessage(`${result.error.code} ${result.error.message}`);
    } else {
      loginDialog = result.value;
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
    }
  });

  const processLoginMessage = (arg: { message: string; origin: string }) => {
    let messageFromDialog = JSON.parse(arg.message);
    if (messageFromDialog.status === "success") {
      // We now have a valid access token.
      loginDialog.close();
      setAccessToken(messageFromDialog.accessToken);
      setAuthStatus("loggedIn");
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      loginDialog.close();
      setErrorMessage(messageFromDialog.result);
    }
  };

  const processLoginDialogEvent = (arg) => {
    processDialogEvent(arg, setAuthStatus, setErrorMessage);
  };
};

const processDialogEvent = (
  arg: { error: number; type: string },
  setAuthStatus: (x: string) => void,
  setErrorMessage: (x: string) => void
) => {
  switch (arg.error) {
    case 12002:
      setErrorMessage(
        "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      setErrorMessage("The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.");
      break;
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      setAuthStatus("notLoggedIn");
      break;
    default:
      setErrorMessage("Unknown error in dialog box.");
      break;
  }
};
