import * as React from "react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import { makeStyles } from "@fluentui/react-components";

export interface OfficeAddinMessageBarProps {
  onDismiss: () => void;
  message: string;
}

const useStyles = makeStyles({
  root: {
    paddingRight: "20px",
  },
});

export default function OfficeAddinMessageBar(props: OfficeAddinMessageBarProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
        onDismiss={props.onDismiss}
        dismissButtonAriaLabel="Close"
      >
        {props.message}.{" "}
      </MessageBar>
    </div>
  );
}
