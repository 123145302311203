import * as React from "react";
import { Button, Divider, makeStyles } from "@fluentui/react-components";

interface FooterProps {
  insertFunction: () => void;
}

const useStyles = makeStyles({
  root: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "calc(100% - 2rem)",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "1rem",
    backgroundColor: "white",
  },
  mr: {
    marginRight: "1rem",
  },
  mb: {
    marginBottom: "1rem",
  },
});

export default function Footer(props: FooterProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Divider className={styles.mb} />
      <Button shape="square" appearance="primary" className={styles.mr} onClick={props.insertFunction}>
        Importuj
      </Button>
      <Button
        shape="square"
        as="a"
        href="https://sarotapr.sharepoint.com/sites/rozliczenia/Lists/Opisy%20usug/AllItems.aspx?OR=Teams%2DHL&CT=1682684213858&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yMzA0MDkwMTMwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
      >
        Przejdź do listy usług
      </Button>
    </div>
  );
}
