import * as React from "react";
import { Divider, Subtitle2, Text, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

interface EntryProps {
  name: string;
  content: string;
  id: string;
  selected: string;
  setSelected: (id: string) => void;
  insertFunction: () => void;
}

const regex = /(&nbsp;|<([^>]+)>)/gi;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    ...shorthands.padding("1rem"),

    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  selected: {
    backgroundColor: "#0078D4",
    color: "white",
    "&:hover": {
      backgroundColor: "#005A9F",
    },
  },
  notSelected: {},
});

export default function Entry(props: EntryProps) {
  const styles = useStyles();

  const clickFunction = () => {
    if (props.selected === props.id) {
      props.insertFunction();
    } else {
      props.setSelected(props.id);
    }
  };

  return (
    <>
      <div
        className={mergeClasses(styles.root, props.id === props.selected ? styles.selected : styles.notSelected)}
        onClick={clickFunction}
      >
        <Subtitle2>{props.name}</Subtitle2>
        <Text>{props.content.replace(regex, "")}</Text>
      </div>
      <Divider />
    </>
  );
}
